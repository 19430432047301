<template>
  <div>
    <b-table-simple caption-top responsive bordered>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="text-center">STT</b-th>
          <b-th class="text-center">Mission</b-th>
          <b-th class="text-center">Key</b-th>
          <b-th class="text-center">Reward Type</b-th>
          <b-th class="text-center">Number</b-th>
          <b-th class="text-center">Action</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item, index) in daily_quests">
          <b-tr :key="item._id || index">
            <b-th class="text-center" :rowspan="item.rewards.length">{{ index + 1 }}</b-th>
            <b-th class="text-center" :rowspan="item.rewards.length">
              <!-- <ui-component :component-data="item.name" /> -->
              <render-translatable-text :component-data="item.name" />
            </b-th>
            <b-th class="text-center" :rowspan="item.rewards.length">
              {{ item.key }}
            </b-th>
            <b-th class="text-center">{{ item.rewards[0].type }}</b-th>
            <b-th class="text-center">{{ item.rewards[0].amount }}</b-th>
            <b-th class="text-center" :rowspan="item.rewards.length">
              <b-button
                variant="gradient-warning"
                style="margin-right: 5px"
                class="btn-icon"
                @click="editDailyQuest(item._id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <!-- <b-button
                @click="confirmDelete(item._id)"
                variant="gradient-danger"
                class="btn-icon"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button> -->
            </b-th>
          </b-tr>
          <template v-for="(reward, i) in item.rewards">
            <b-tr
              v-if="item.rewards.length > 1 && i >= 1"
              :key="reward._id || i"
            >
              <b-th class="text-center">{{ reward.type }}</b-th>
              <b-th class="text-center">{{ reward.amount }}</b-th>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
// import service from "../../service";

export default {
  props: {
    daily_quests: { type: Array, required: true },
  },
  methods: {
    // confirmDelete(id) {
    //   this.$bvModal
    //     .msgBoxConfirm("Are you sure ?", {
    //       title: "Please Confirm",
    //       size: "md",
    //       okVariant: "danger",
    //       okTitle: "Yes",
    //       cancelTitle: "No",
    //       cancelVariant: "outline-secondary",
    //       hideHeaderClose: true,
    //       centered: true,
    //     })
    //     .then((value) => {
    //       if (value === true) {
    //         this.deleteEternal(id);
    //       }
    //     });
    // },
    // async deleteEternal(id) {
    //   await service.delete({ id: id });
    //   this.$emit("getList");
    // },
    editDailyQuest(id) {
      this.$router.push({
        name: "quest-daily-detail",
        params: { daily_id: id },
      });
    },
  },
};
</script>
